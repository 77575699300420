import * as React from 'react';
import { Flex, WhiteSpace, WingBlank, Button, List, InputItem, Radio, TextareaItem, Slider, Toast, ActivityIndicator } from 'antd-mobile';
import { Avatar, Typography, Layout, Row, Col } from 'antd';
import appConfig from './appConfig.json';
import axios from 'axios';
import './App.css';
import { DeleteOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
const { Content } = Layout;
const { Title, Text } = Typography;

const RootLayout = styled(Layout)`
  min-height: 100vh;
`;
const ImageCol = styled(Col)`
  background-image: url(/baby-215303_1280.jpg);
  background-repeat: no-repeat;
  background-color: #999;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
`;
const CloseAvatar = styled(Avatar)`
  margin: 8px;
  background-color: #ff9999;
`;
const SliderWrapper = styled.div`
  .am-slider-mark {
    font-size: 22px;
  }
`;

interface Values {
  email?: string;
  username?: string;
  reason?: string;
  description?: string;
  recommendationScore?: number;
}

export function CloseAccountPage() {
  const { register, unregister, watch, handleSubmit, control, setValue, getValues, errors, formState } = useForm({defaultValues: {recommendationScore: 10} as Values});
  React.useEffect(() => {
    register({name: 'reason'}, {required: true});
    return () => unregister('reason');
  }, [register, unregister]);
  const watchReason = watch('reason');
  React.useEffect(() => {
    register({name: 'recommendationScore'}, {required: true});
    return () => unregister('recommendationScore');
  }, [register, unregister]);
  const watchRecommendationScore = watch('recommendationScore');
  const history = useHistory();

  async function submitForm(data: any) {
    console.debug('POST-ing...', data);
    try {
      // https://jira.camunda.com/browse/CAM-11840
      const res = await axios.post(`${appConfig.camundaApiUrl}/process-definition/key/marketing.user.CloseAccount/start`,
        {
          variables: {
            email: {type: 'string', value: data.email},
            slug: {type: 'string', value: data.slug},
            reason: {type: 'string', value: data.reason},
            description: {type: 'string', value: data.description},
            recommendationScore: {type: 'integer', value: data.recommendationScore},
          }
        },
        {
          auth: {
            username: appConfig.camundaUsername,
            password: appConfig.camundaPassword,
          },
        });
      console.debug('Success:', res.data);
      history.push('/account/close/pending');
    } catch (e) {
      alert(e);
    }
  }
  React.useEffect(() => {
    if (Object.keys(errors).length >= 1) {
      Toast.fail('Silakan lengkapi formulir terlebih dahulu');
    }
  }, [errors]);

  const reasonOptions = [
    {value: 'has_partner', label: 'Sudah menemukan pasangan'},
    {value: 'no_longer_using', label: 'Akun sudah tidak dipakai lagi'},
    {value: 'dislike_website', label: 'Tidak suka dengan websitenya'},
    {value: 'not_believe_online_app', label: 'Tidak percaya biro jodoh online'},
    {value: 'scam', label: 'Ini penipuan'},
    {value: 'other', label: 'Lainnya (tulis di keterangan)'},
  ];
  // console.log('values', getValues(), 'reason=', watchReason);
  return (
    <RootLayout>
      <Content style={{}}>
        <Row>
          <ImageCol xs={0} sm={8} md={14}>
          </ImageCol>
          <Col xs={24} sm={16} md={10}>
            <WingBlank>
              <Flex align="center" direction="column">
                <CloseAvatar size="large" icon={<DeleteOutlined />} />
                <Title level={2}>
                  Close Account
                </Title>
                <Text type="danger">
                  Really sad to see you go <span role="img" aria-label="sad">😢</span>
                </Text>
              </Flex>
            </WingBlank>
            <form onSubmit={handleSubmit(submitForm)}>
              <List renderHeader={() => 'Email Anda'}>
                <Controller as={<InputItem><MailOutlined /></InputItem>} name="email" control={control}
                  rules={{
                    validate: email => (email === null || email === '' || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))}}
                  clear
                  placeholder="user@domain.com" />
              </List>
              <ErrorMessage errors={errors} name="email" message="Format email salah">
                {({message}) => <WingBlank><Text type="danger">{message}</Text></WingBlank>}
              </ErrorMessage>

              <List renderHeader={() => 'Username Anda'}>
                <Controller as={<InputItem><UserOutlined /></InputItem>} name="slug" control={control}
                  rules={{
                    validate: slug => slug != null || getValues('email') != null}}
                  clear placeholder="Username Anda" />
              </List>
              <ErrorMessage errors={errors} name="slug" message="Anda harus mengisi minimal satu dari email atau username.">
                {({message}) => <WingBlank><Text type="danger">{message}</Text></WingBlank>}
              </ErrorMessage>

              <List renderHeader={() => 'Alasan menutup akun'}>
                {reasonOptions.map(it => (
                  <Radio.RadioItem key={it.value} name="reason" checked={watchReason === it.value}
                    onChange={() => {setValue('reason', it.value, true); console.log('setting', getValues()); return false; }}>{it.label}</Radio.RadioItem>
                ))}
                {/* {reasonOptions.map(it => (
                  <Controller 
                    name="reason"
                    key={it.value}
                    as={<Radio.RadioItem checked={watchReason == it.value}>{it.label}</Radio.RadioItem>}
                    control={control}
                    onChange={(value) => value ? it.value : null}
                    valueName="checked"
                    />
                ))} */}
              </List>
              <ErrorMessage errors={errors} name="reason" message="Silakan pilih alasan menutup akun.">
                {({message}) => <Text type="danger">{message}</Text>}
              </ErrorMessage>

              <List renderHeader={() => 'Keterangan'}>
                <Controller as={TextareaItem} name="description" control={control}
                  clear
                  placeholder="Tulis keterangan tambahan Anda"
                  autoHeight />
              </List>
              <List renderHeader={() => 'Seberapa Anda akan merekomendasikan SatukanCinta/Miluv ke teman atau rekan Anda?'}>
                <List.Item>
                  <SliderWrapper>
                    <div style={{padding: 24, paddingTop: 16, paddingBottom: 56}}>
                      {/* <Controller
                        as={Slider}
                        name="recommendationScore"
                        control={control}
                        defaultValue={10}
                        min={0}
                        max={10}
                        marks={{0: '😠', 4: '🙁', 7: '😐', 10: '🤩'}}
                        included={false} /> */}
                      <Slider
                        defaultValue={10}
                        min={0}
                        max={10}
                        marks={{0: '😠', 4: '🙁', 7: '😐', 10: '🤩'}}
                        value={watchRecommendationScore}
                        included={false}
                        onChange={e => setValue('recommendationScore', e!)} />
                    </div>
                  </SliderWrapper>
                </List.Item>
              </List>
              <WhiteSpace size="lg" />
              {formState.isSubmitting && <ActivityIndicator size="large" toast text="Submitting..." />}
              <WingBlank>
                <Button
                    type="warning"
                    disabled={formState.isSubmitting}
                    onClick={handleSubmit(submitForm)}
                  >
                    Close Account
                </Button>
              </WingBlank>
            </form>
        </Col>
        </Row>
      </Content>
     {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      {/* <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
     </Grid> */}
    </RootLayout>
  );
}