import * as React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { CloseAccountPage } from './CloseAccountPage';
import { CloseAccountPendingPage } from './CloseAccountPendingPage';
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/account/close" exact>
          <CloseAccountPage/>
        </Route>
        <Route path="/account/close/pending" exact>
          <CloseAccountPendingPage/>
        </Route>
      </Switch>
    </Router>
  )
}
