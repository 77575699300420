import * as React from 'react';
import { Flex, WingBlank, WhiteSpace } from 'antd-mobile';
import { Avatar, Typography, Row, Col } from 'antd';
import './App.css';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
const { Title, Text } = Typography;

const RootLayout = styled.div`
  min-height: '100vh';
  background-color: #f0f2f5;
`;
const ImageCol = styled(Col)`
  background-image: url(/baby-215303_1280.jpg);
  background-repeat: no-repeat;
  background-color: #999;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
`;
const CloseAvatar = styled(Avatar)`
  margin: 8px;
  background-color: #ff9999;
`;
const CenterP = styled.p`
  text-align: center;
`;

export function CloseAccountPendingPage() {
  return (
    <RootLayout>
      <Row style={{minHeight: '100vh'}}>
        <ImageCol xs={0} sm={8} md={14}>
        </ImageCol>
        <Col xs={24} sm={16} md={10}>
          <WingBlank>
            <Flex align="center" direction="column">
              <CloseAvatar size="large" icon={<DeleteOutlined />} />
              <Title level={2}>
                Close Account
              </Title>
              <Text type="danger">
                Really sad to see you go <span role="img" aria-label="sad">😢</span>
              </Text>
              <WhiteSpace size="lg" />
              <CenterP>Your Close Account request is being processed.</CenterP>
              <CenterP>We will let you know when your account has been closed.</CenterP>
            </Flex>
          </WingBlank>
        </Col>
      </Row>
    </RootLayout>
  );
}